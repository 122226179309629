import React from 'react';

import Head from 'next/head';

function Error404() {
  return (
    <>
      <Head>
        <title>{`Page Not Found | TR-OTM`}</title>
      </Head>
      <div>Error 404: Not Found</div>
    </>
  );
}

export default Error404;
